<!--
 * @Descripttion: 绩效金/提成设置
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:40:06
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-27 09:31:53
-->
<template>
    <div class="royaltymeSetting">
        <div class="rs-title">
            <div class="mt-title">绩效金提成管理</div>
            <div class="mt-add">
                <div class="type">
                    <div class="tag">销售类型</div>
                    <el-select @change="changeSelect" v-model="marketValue" placeholder="请选择">
                        <el-option
                        v-for="item in marketOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <!-- <div v-if="!isPyramidBtnShow" class="add" @click="isPyramidShow = true">添加绩效金办法</div>
                <div v-if="!isRoyaltiesBtnShow" class="add" @click="isRoyaltiesShow = true">添加提成办法</div> -->
                <div class="add" @click="clickSave">保存</div>
            </div>
        </div>
        <!-- 绩效金办法 -->
        <div class="rs-jxj-box">
            <div class="rjb-title">
                <div class="r-t-left">{{marketName}}销售<span>绩效金办法</span></div>
                <!-- <div class="r-t-right">
                    <div class="btn-wrap" v-if="isPyramidBtnShow">
                        <div class="n-btn" @click="clickPyramidEdit">修改</div>
                        <div class="n-btn" @click="clickPyramidDel">删除</div>
                    </div>
                    <div class="btn-wrap" v-else>
                        <div class="btn" @click="isPyramidShow = false">取消</div>
                        <div class="btn submit" @click="clickSubmitPyramid">确定</div>
                    </div>
                </div> -->
            </div>
            <div class="rjb-box">
                <div class="r-b-left">
                    <div class="l-item">
                        <div class="title">绩效金基数</div>
                        <!-- <div v-if="isPyramidBtnShow">
                            <div class="price">{{info.performance_base}}</div>
                        </div>
                        <div v-else> -->
                        <el-input v-model="info.performance_base" placeholder="请输入绩效金基数"></el-input>
                        <!-- </div> -->
                        <div class="unit">元</div>
                    </div>
                    <div class="l-item">
                        <div class="title">目标销售额</div>
                        <!-- <div v-if="isPyramidBtnShow">
                            <div class="price">{{info.performance_target}}</div>
                        </div> -->
                        <!-- <div v-else> -->
                        <el-input v-model="info.performance_target" placeholder="请输入目标销售额"></el-input>
                        <!-- </div> -->
                        <div class="unit">元</div>
                    </div>
                </div>
                <div class="r-b-right">
                    <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20231222/19ca35534426133bd2bb768107875b4a.png" />
                </div>
            </div>
        </div>
        <!-- 提成办法 -->
        <div class="rs-jxj-box">
            <div class="rjb-title">
                <div class="r-t-left">{{marketName}}销售<span>提成办法</span></div>
                <!-- <div class="r-t-right">
                    <div class="btn-wrap" v-if="isRoyaltiesBtnShow">
                        <div class="n-btn" @click="clickRoyaltiesEdit">修改</div>
                        <div class="n-btn" @click="clickRoyaltiesDel">删除</div>
                    </div>
                    <div class="btn-wrap" v-else>
                        <div class="btn" @click="isRoyaltiesShow = false">取消</div>
                        <div class="btn submit" @click="clickSubmitRoyalties">确定</div>
                    </div>
                </div> -->
            </div>
            <div class="rjb-box">
                <div class="r-b-left">
                    <div class="l-item">
                        <div class="title">销售额 x</div>
                        <!-- <div v-if="isRoyaltiesBtnShow">
                            <div class="price">{{royalties}}</div>
                        </div>
                        <div v-else> -->
                            <el-input v-model="info.commission" placeholder="请输入数字"></el-input>
                        <!-- </div> -->
                        <div class="unit">%</div>
                    </div>
                    <div class="l-item">
                        <div class="title" style="margin-right: 20px">是否计算续费</div>
                        <el-switch
                            @change="changeIsChange(info)"
                            v-model="info.count_renew"
                            active-color="#0068FF"
                            inactive-color="#c0c0c0">
                        </el-switch>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提示 -->
        <!-- <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">删除后，客服部销售从删除开始不再执行该绩效金，确定删除？</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="isDel = false">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'royaltymeSetting',
            marketOptions: [],
            marketValue: '',
            isDel: false,
            isPyramidShow: false,
            isPyramidBtnShow: false,
            isRoyaltiesShow: false,
            isRoyaltiesBtnShow: false,
            info: {
                performance_base: '',
                performance_target: '',
                commission: ''
            },
            marketName: ''
            // pyramid: {
            //     cardinality: '',
            //     saleroom: ''
            // },
            // royalties: ''
        }
    },
    mounted () {
        this.getSaleTypeWhole();
    },
    methods: {
        // 是否计算续费
        changeIsChange(item){

        },
        // 保存
        clickSave(){
            if(this.info.performance_base == ''){
                this.$message.error('请输入绩效金基数')
                return
            }
            if(this.info.performance_target == ''){
                this.$message.error('请输入目标销售额')
                return
            }
            if(this.info.commission == ''){
                this.$message.error('请输入提成数字')
                return
            }
            let count_renew = this.info.count_renew?1:0
            let params = {
                type_id: this.marketValue,
                performance_base: this.info.performance_base,
                performance_target: this.info.performance_target,
                commission: this.info.commission,
                count_renew: count_renew
            }
            api.post('/service/sale/set_sale_performance_commission', params, (res) =>  {
                this.$message.success('设置成功')
                this.getSalePerformance()
            });
        },
        // 选择类型
        changeSelect(e){
            let name = "";
            this.marketOptions.forEach(item => {
                if (item.id === e) {
                    name = item.name;
                }
            });
            this.marketName = name
            this.getSalePerformance();
        },
        // 获取销售绩效提成
        getSalePerformance(){
            let params = {
                type_id: this.marketValue
            }
            api.get('/service/sale/get_sale_performance_commission', params, (res) =>  {
                if(res.data.data){
                    this.info = res.data.data
                    this.info.count_renew = this.info.count_renew == 1?true:false
                }else{
                    let info = {
                        performance_base: '',
                        performance_target: '',
                        commission: '',
                        count_renew: false
                    }
                    this.info = info
                }
            });
        },
        // 获取销售类型
        getSaleTypeWhole(){
            api.get('/service/sale/get_sale_type_whole', {}, (res) =>  {
                this.marketOptions = res.data.list;
                this.marketValue = res.data.list[0].id;
                this.marketName = res.data.list[0].name;
                this.getSalePerformance();
            });
        },
        // 删除提成办法
        // clickRoyaltiesDel(){
        //     this.isDel = true
        // },
        // 修改提成办法
        // clickRoyaltiesEdit(){
        //     this.isRoyaltiesBtnShow = false
        // },
        // 确定添加提成办法
        // clickSubmitRoyalties(){
        //     if(this.royalties == ''){
        //         this.$message.error('请输入数字')
        //         return
        //     }
        //     this.isRoyaltiesBtnShow = true
        // },
        // 确定删除
        // clickConfirm(){
        //     this.isPyramidShow = false
        //     this.isPyramidBtnShow = false

        //     this.isRoyaltiesShow = false
        //     this.isRoyaltiesBtnShow = false

        //     this.isDel = false
        // },
        // 删除绩效金办法
        // clickPyramidDel(){
        //     this.isDel = true
        // },
        // // 修改绩效金办法
        // clickPyramidEdit(){
        //     this.isPyramidBtnShow = false
        // },
        // 确定添加绩效金办法
        // clickSubmitPyramid(){
        //     if(this.pyramid.cardinality == ''){
        //         this.$message.error('绩效金基数不能为空！')
        //         return
        //     }
        //     if(this.pyramid.saleroom == ''){
        //         this.$message.error('目标销售额不能为空！')
        //         return
        //     }
        //     this.isPyramidBtnShow = true
        // },
    },
}
</script>

<style lang='scss'>
.royaltymeSetting{
    .rs-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .mt-title{
            font-size: 24px;
            color: #101010;
        }
        .mt-add{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .type{
                margin-right: 10px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .tag{
                    font-size: 16px;
                    color: #101010;
                    margin-right: 15px;
                }
                .el-select{
                    width: 130px;
                }
            }
            .add{
                margin-left: 30px;
                width: 100px;
                height: 38px;
                border-radius: 6px;
                border: 1px solid #0068ff;
                background: #0068ff;
                font-size: 14px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .rs-jxj-box{
        background: #fff;
        margin-top: 15px;
        .rjb-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ddd;
            padding: 10px 40px;
            .r-t-left{
                font-size: 18px;
                font-weight: bold;
                span{
                    font-weight: 400;
                    margin-left: 10px;
                }
            }
            .r-t-right{
                .btn-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .btn{
                        width: 100px;
                        height: 35px;
                        border-radius: 6px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        color: #0068ff;
                        border: 1px solid #0068ff;
                        cursor: pointer;
                    }
                    .submit{
                        background: #0068ff;
                        color: #fff;
                        margin-left: 20px;
                    }
                    .n-btn{
                        background: #fff;
                        color: #0068ff;
                        margin-left: 50px;
                        cursor: pointer;
                    }
                }
            }
        }
        .rjb-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 40px;
            .r-b-left{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .l-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    font-size: 16px;
                    color: #101010;
                    // margin-right: 100px;
                    margin: 5px 100px 5px 0;
                    .el-input{
                        width: 200px;
                        margin: 0 20px;
                    }
                    .price{
                        font-size: 30px;
                        color: #101010;
                        margin: 0 20px;
                    }
                }
            }
            .r-b-right{
                width: 450px;
                height: 120px;
                background: #eff3f9;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                .img{
                    width: 100%;
                    height: 100%;
                    vertical-align: bottom;
                }
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }
}
</style>
